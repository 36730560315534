import React from 'react'
import { graphql } from 'gatsby'

import Carousel from '../../components/carousel'
import Layout from '../../components/layout'
import SEO from '../../components/seo'


export default ({ data }) => {
  const images = [
    { img: data.i1, caption: 'University of South Carolina — Columbia, South Carolina' },
    { img: data.i2, caption: 'University of South Carolina — Columbia, South Carolina' },
    { img: data.i3, caption: 'University of Kansas — Lawrence, Kansas' },
    { img: data.i4, caption: 'University of Kansas — Lawrence, Kansas' },
    { img: data.i5, caption: 'UMASS Lowell — Lowell, Massachusetts' },
    { img: data.i6, caption: 'UMASS Lowell — Lowell, Massachusetts' },
    { img: data.i7, caption: 'Davidson College — Davidson, North Carolina' },
    { img: data.i8, caption: 'Davidson College — Davidson, North Carolina' },
    { img: data.i9, caption: 'Jackson State University Student Housing — Jackson, Mississippi' },
    { img: data.i10, caption: 'Jackson State University Student Housing — Jackson, Mississippi' },
    { img: data.i11, caption: 'Atlanta College of Arts Student Housing — Atlanta, Georgia' },
    { img: data.i12, caption: 'Atlanta College of Arts Student Housing — Atlanta, Georgia' },
    { img: data.i13, caption: 'Georgia Tech Family Housing — Atlanta, Georgia' },
    { img: data.i14, caption: 'University of Arkansas - Maple Hill Dorms — Fayetteville, Arkansas' },
  ];

  return (
    <Layout>
      <SEO title="Gallery: Student Housing" keywords={[]}/>
      <div className="container gallery-container">
        <h2 className='subtitle subtitle--xl color-primary'>Gallery</h2>
        <h1 className='title title--xxxl color-primary'>Student Housing</h1>
        <div className='carousel-container carousel-container--lg'>
          <Carousel
            content={images}
            carWidth={846}
            carHeight={476}
          />
        </div>
        <div>
          <p>The Infinity System is ideal for Student Housing projects
            up to 10-Stories, offering the following advantages:</p>

          <p><strong>Economical:</strong> Lower Costs than traditional masonry,
            steel, pre-cast concrete or formed-in-place concrete framing.</p>

          <p><strong>Speed:</strong> Fast installation enables quick project completion
            under the tight schedules that Student Housing always seems to have.</p>

          <p><strong>Quality:</strong> Superior noise isolation from
            room-to-room and floor-to-floor. Longer building life
            expectancy as well as lower long-term maintenance costs.</p>

          <p><strong>Noncombustible:</strong> Metal stud walls and
            concrete floor slabs provide safe, noncombustible living
            quarters for students and peace of mind for their parents.</p>

          <p><strong>Creative Interior Finishes:</strong> The underside of the Epicore MSR Floor
            deck can simply be painted for an economical, attractive and durable ceiling finish.</p>
        </div>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
         query {
           i1: file(relativePath: { eq: "gallery/student-housing/gallery/01-UniSC010_(43027).png" }) {
             ...GalleryCarouselImageFragment
           }
           i2: file(relativePath: { eq: "gallery/student-housing/gallery/02-USC_Dorms-Finished1_(NEW).JPG" }) {
             ...GalleryCarouselImageFragment
           }
           i3: file(relativePath: { eq: "gallery/student-housing/gallery/03-UniKS030_(43025).png" }) {
             ...GalleryCarouselImageFragment
           }
           i4: file(relativePath: { eq: "gallery/student-housing/gallery/04-UniKS050_(43301).png" }) {
             ...GalleryCarouselImageFragment
           }
           i5: file(relativePath: { eq: "gallery/student-housing/gallery/05-UMASS010_(43018).png" }) {
             ...GalleryCarouselImageFragment
           }
           i6: file(relativePath: { eq: "gallery/student-housing/gallery/06-UMASS040_(43021).png" }) {
             ...GalleryCarouselImageFragment
           }
           i7: file(relativePath: { eq: "gallery/student-housing/gallery/07-DaviC020_(42872).png" }) {
             ...GalleryCarouselImageFragment
           }
           i8: file(relativePath: { eq: "gallery/student-housing/gallery/08-DaviC030_(42873).png" }) {
             ...GalleryCarouselImageFragment
           }
           i9: file(relativePath: { eq: "gallery/student-housing/gallery/09-JackU020_(40648).png" }) {
             ...GalleryCarouselImageFragment
           }
           i10: file(relativePath: { eq: "gallery/student-housing/gallery/10-JackU030_(41320).png" }) {
             ...GalleryCarouselImageFragment
           }
           i11: file(relativePath: { eq: "gallery/student-housing/gallery/11-AtCol010_(34236).png" }) {
             ...GalleryCarouselImageFragment
           }
           i12: file(relativePath: { eq: "gallery/student-housing/gallery/12-AtCol030_(34350).png" }) {
             ...GalleryCarouselImageFragment
           }
           i13: file(relativePath: { eq: "gallery/student-housing/gallery/13-GTech010_(40639).png" }) {
             ...GalleryCarouselImageFragment
           }
           i14: file(relativePath: { eq: "gallery/student-housing/gallery/14-UARKM010_(41366).png" }) {
             ...GalleryCarouselImageFragment
           }
         }
       `
